import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from "@core/utils/utils";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useItemCategoriesList() {
  // Use toast
  const toast = useToast();
  const { route, router } = useRouter();

  const refCategoriesListTable = ref(null);

  // filter options
  const categoriesStatusOptions = [
    {label: "Active", key: true},
    {label: "Inactive", key: false},
  ];

  // Table Handlers
  const tableColumns = [
    { label: "Nº", key: "index", sortable: true },
    { label: "libellé", key: "name", sortable: true },
    { label: "description", key: "description" },
    { label: "Parent", key: "parent", formatter: (c) => c ? c.name : '' },
    { label: "Statut", key: "is_active", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const categoryStatusFilter = ref(null);

  const categoriesList = computed(() => {
    return store.getters["itemCategory/all"];
  });

  const totalCategories = computed(() => {
    const meta = store.getters["itemCategory/meta"];
    return meta.total ?? 0;
  });

  const dataMeta = computed(() => {
    const localItemsCount = refCategoriesListTable.value
      ? refCategoriesListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCategories.value,
    };
  });

  const refetchData = () => {
    refCategoriesListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, categoryStatusFilter],
    () => {
      refetchData();
    }
  );

  const fetchItemCategoriesList = (ctx, callback) => {
    store
      .dispatch("itemCategory/fetchCategoriesList", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        is_active: categoryStatusFilter.value,
      })
      .then(() => {
        callback(categoriesList.value);
      });
  };

  const deleteCategory = async (categoryID) => {
    const { data, status } = await store.dispatch("itemCategory/destroyCategory", {
      id: categoryID,
    });
    if (status != 200) {
      toast({
        component: ToastificationContent,
        props: {
          title: data.error,
          icon: "AlertTriangleIcon",
          variant: "error",
        },
      });
    } else {
      toast({
        component: ToastificationContent,
        props: {
          title: "La catégorie a été supprimée avec succès.",
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
      refetchData();
    }
  };

  return {
    fetchItemCategoriesList,
    tableColumns,
    perPage,
    currentPage,
    totalCategories,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCategoriesListTable,
    refetchData,
    deleteCategory,

    categoryStatusFilter,
    categoriesStatusOptions,
  };
}
